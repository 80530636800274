@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'maplibre-gl/dist/maplibre-gl.css';

/* Reverse the items in the charts tooltip see keyinfo.tsx. There is a itemSorter function but it doesn't seem to work. */
.recharts-tooltip-item-list {
  display: flex;
  flex-direction: column-reverse;
}
